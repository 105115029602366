.body {
    background-color: #fff;
    font-family: 'Noto Sans KR', sans-serif;
    margin: 0;
}

.header {
    background-color: #fff;
}
.header.header-sticky {
    margin: 0 !important;
}
.header-nav .nav-link {
    padding: 0px 22px;
    color: #141414;
    font-size: 18px;
}
.h-btn-w {
    padding: 0px 22px;
    color: #141414;
    font-size: 18px;
    cursor: pointer;
}
.header-nav .nav-link:hover, .header-nav .nav-link:focus {
    color: #0076db;
}

.nav-link-active {
    color: #0076db !important;
    font-weight: 600;
}

.footer {
    border-top: 0px;
    background-color: #f8f8f8;
    text-align: center;
    padding: 50px 0px;
}

.container {
    width: 1280px !important;
    max-width: none !important;
}
.container-bottom {
    width: 100% !important;
    min-width: 1280px !important;
    max-width: none !important;
    padding: 0;
}

.px-3 {
    padding: 0 !important;
    margin: 0 !important;
}

.cover {
    object-fit: cover;
}

.page-item.active .page-link {
    background-color: #141414;
    border-color: #141414;
    border-radius: 50%;
    padding: 5px 15px;
    margin: 0 4px;

}

.page-link {
    color: #141414;
    border: 0px;
}

.page-link:hover {
    color:#0076db;
    background-color: #ffff;
    border-color: #ffffff;
    border: 0px;
}

.form-control {
    border: 1px solid var(--cui-input-border-color, #e4e4e4);
    border-radius: 6px;
}

.table > :not(caption) > * > * {
    padding: 24px 0px;
    font-size: 20px;
    color: #252525;
}

.dropdown-toggle::after {
    border-top: none;
    content: none;
}

.dropdown-menu {
    border: 0;
    border-radius: 0px 0px 20px 20px;
    font-size: 18px;
    padding: 0;
}
.dropdown-menu-border {
    padding: 10px 0px;
    border-radius: 0px 0px 20px 20px;
    border-right: 1px solid rgb(216, 219, 224);
    border-bottom: 1px solid rgb(216, 219, 224);
    border-left: 1px solid rgb(216, 219, 224);
    margin-top: 28px;
}

.dropdown-item {
    padding: 10px 0px 10px 18px;
    border-radius: 20px;
    display: block;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item:hover {
    background-color: #fff;
}

.dropdown-item:hover {
    background-color: #fff;
    border-radius: 20px;
    color: #0076db;
    font-weight: 500;
}

.dropdown-item:active {
    background-color: #fff;
    border-radius: 20px;
    color: #0076db;
    font-weight: 500;
}

.dropdown-item:visited {
    background-color: #fff;
    border-radius: 20px;
}


.board-fixed-image-box {
    position: relative;
    width:100%;
}
.board-fixed-image-box:before {
    content: "";
    display: block;
    padding-top: 75%;
}

.board-fixed-image{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
}

.carousel-caption {
    bottom: 35%;
    padding-bottom: 0;
}

.main-h1 {
    font-size: 52px;
    font-weight: 600;
}
.main-h1-m {
    font-size: 30px;
    font-weight: 600;
}
.main-p {
    font-size: 20px;
    padding-top: 16px;
}
.main-p-m {
    font-size: 12px;
    padding-top: 6px;
}
.main-p-top {
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 4px;
}
.main-p-top-m {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 4px;
}
.main-card-st {
    width: 100%;
    border: 0;
    padding: 6px;
}
.main-card-st-m {
    border: 0;
    padding: 6px;
    text-align: center !important;
}

.main-card-title {
    font-weight: 600;
    color: #141414;
    font-size: 28px;
}
.main-card-title-m {
    font-weight: 600;
    color: #141414;
    font-size: 24px;
}
.main-card-text {
    color: #141414;
    font-size: 18px;
    padding-top: 6px;
    font-weight: 400;
}
.main-card-text-m {
    color: #141414;
    font-size: 16px;
    padding-top: 2px;
    font-weight: 400;
}
.card-body-st {
    padding-top: 30px !important;
}
.card-body-st-m {
    padding-top: 20px !important;
    padding-bottom: 30px !important;
}
.card-body {
    padding: 0;
}

.main-btn-st {
    background-color: #fff;
    padding: 10px 40px;
    color: #141414;
    border-radius: 0;
    margin-top: 28px;
    margin-right: 12px;
    font-size: 18px;
    border: #141414 solid 1px;
    position: absolute;
    right: 0;
}
.main-btn-st-m {
    background-color: #fff;
    padding: 8px 20px;
    color: #141414;
    border-radius: 0;
    margin-top: 36px;
    margin-right: 12px;
    font-size: 14px;
    border: #141414 solid 1px;
    position: absolute;
    right: 0;
}
.main-btn-st:hover {
    background-color: #141414;
    color: #fff;
    border: #141414 solid 1px;
}
.main-h1-title {
    color: #141414;
    text-align: left;
    font-weight: 700;
    font-size: 40px;
}
.main-h1-title-m {
    color: #141414;
    text-align: left;
    font-weight: 700;
    font-size: 28px;
}
.main-p-text {
    color: #141414;
    font-size: 18px;
    text-align: left;
    font-weight: 200;
    padding-bottom: 30px;
    padding-top: 6px;
}
.main-bottom-img {
    border-radius: 10px;
    box-shadow: 2px 2px 14px 2px rgba(0, 0, 60, 0.1);
    padding: 22px 18px;
    color: #fff;
    margin: 10px 0px;
    object-fit: cover;
    cursor: pointer;
    background-size: cover;
}

.main-news-st {
    background-color: #fff;
    box-shadow: 4px 10px 20px 6px rgba(0,0,0,.08);
    border-radius: 14px;
    padding: 26px;
    margin: 10px;
}
.main-news-st-m {
    background-color: #fff;
    box-shadow: 4px 10px 20px 6px rgba(0,0,0,.08);
    border-radius: 14px;
    padding: 20px;
    margin: 10px;
    font-size: 16px;
}
.main-program-st {
    background-color: #fff;
    box-shadow: 4px 10px 20px 6px rgba(0,0,0,.08);
    border-radius: 14px;
    padding: 18px;
}
.row-st {
    padding: 0;
    margin: 0;
}
.main-bottom-img-hover {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    overflow: hidden;
}

// .main-bottom-img-hover img {
//     transition: all 0.2s linear;
// }
// .main-bottom-img-hover:hover img {
//     transform: scale(1.2);
// }
.main-bottom-img-hover-txt {
    text-align: left;
    color: #fff;
}
.margin-st {
    margin: 0 !important;
}
.main-bottom-img-hover-icon {
    padding-right: 6px;
    text-align: right;
    padding-top: 6px;
}
.txt-icon-row {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    transform: translateY(-50%);
}
.swiper-button-next, .swiper-button-prev {
    color: #fff !important;
    background-color:rgba(0, 0, 0, 0.35);
    padding: 36px;
    border-radius: 50px;
    top: 45% !important;
}
.span-p-st {
    font-size: 18px;
    font-weight: 400;
    color: #414141;
}
.badge {
    font-weight: 500;
}
.bg-info {
    background-color: #fff !important;
    border: 2px solid #2b80ff;
    color: #2b80ff;
    margin-bottom: 4px;
    font-size: 12px;
    border-radius: 20px;
}
.bg-danger {
    background-color: #fff !important;
    border: 2px solid #ff6a6a;
    color: #ff6a6a;
    margin-bottom: 4px;
    font-size: 12px;
    border-radius: 20px;
}
.sub-p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: #141414;
}
.sub-p-m {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #141414;
}

.btn-close {
    background-color: none;
    border-radius: 50%;
    bottom: 104px;
    right: 8px;
    z-index: 20;
    position: absolute;
}
.btn-close:hover {
    background-color: none !important;
    --cui-btn-hover-bg: none;
    --cui-btn-active-bg: none;
}
.modal-content {
    border-radius: 12px;
    border: 0;
}
.trainer-btn-w {
    background-color: #f8f8f8;
    padding: 10px 40px;
    border: 0;
    border-radius: 30px;
    font-size: 22px;
    font-weight: 500;
    margin: 0px 20px;
}
.trainer-btn-m {
    background-color: #f8f8f8;
    padding: 5px 14px;
    border: 0;
    border-radius: 30px;
    font-size: 15px;
    margin: 5px;
    font-weight: 600;
}
.horseriding-btn-w {
    background-color: #cfcfdd;
    padding: 14px 40px;
    border: 0;
    border-radius: 40px;
    font-size: 22px;
    font-weight: 500;
    margin: 0px 20px;
}
.horseriding-btn-m {
    background-color: #f8f8f8;
    padding: 10px 32px;
    border: 0;
    border-radius: 30px;
    font-size: 15px;
    margin: 5px;
    font-weight: 600;
}
.input-css-m {
    border: 0px;
    border-radius: 30px 0px 0px 30px;
    padding: 10px 0px 10px 20px;
    font-weight: 300;
    background-color: #f8f8f8;
}
.input-css {
    border: 0px;
    border-radius: 30px 0px 0px 30px;
    padding: 12px 0px 12px 20px;
    font-weight: 300;
    background-color: #f8f8f8;
}
.input-btn-css {
    background-color: #f8f8f8;
    border: 0;
    border-radius: 0px 30px 30px 0px;
    padding-right: 20px;
}
.input-css:focus {
    background-color: #f8f8f8;
}
.input-btn-css:hover, .input-btn-css:active .input-btn-css:invalid, .input-btn-css:link {
    background-color: #f8f8f8;
}
.btn-primary {
    --cui-btn-hover-bg: #f8f8f8 !important;
}
.navbar-toggler {
    border: 0;
    padding: 0;
    padding-top: 6px;
}
.row-st-none-header {
    margin: 0;
    --cui-gutter-x: 0;
}
.offcanvas-end {
    top: 0;
    width: 260px;
    right: 0 !important;
}

.close-btn-header {
    top: 20px;
    right: 20px;
}
.nav-m-st {
    padding: 8px 8px 6px 22px !important;
    color: #141414 !important;
    font-size: 18px;
    font-weight: 500;
}
.nav-m-st-s {
    padding: 16px 8px 4px 22px !important;
    color: #141414 !important;
    font-size: 14px;
    font-weight: 300;
}
.m-category-btn {
    white-space: nowrap;
    overflow-x: auto;
    width: calc(100%);
    -ms-overflow-style: none;
    padding: 20px 0px 0px 10px;
}
.m-category-btn::-webkit-scrollbar{
    display:none;
}
.table {
    white-space: pre-line;  //개행 문자(‘\n’)을 잘 인식하여 줄바꿈 가능해짐
}
.news-list-btn {
    color: #fff;
    background-color: #141414;
    border-color: #141414;
    padding: 14px 42px;
    border-radius: 5rem;
    font-size: 18px;
    font-weight: 400;
    margin-top: 50px;
    margin-bottom: 50px;
}
.news-list-btn:hover {
    color: #fff;
    background-color: #ffc320;
    border-color: #ffc320;
}
.news-list-btn-m {
    color: #fff;
    background-color: #141414;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-radius: 50px;
    margin: 30px 0px 50px 0px;
}

.image-gallery-icon {
    color: rgba(0, 0, 0, 0.6);
    filter: none;
}
.image-gallery-icon:hover  {
    color: #ffc320;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 350px) !important;
}
.image-gallery-content.fullscreen {
    padding-top: 120px;
}
.btn-outline-info {
    --cui-btn-border-color: #39f;
    --cui-btn-color: #fff;
    background-color: #39f !important;
    --cui-btn-hover-bg: #39f;
    --cui-btn-hover-border-color: #39f;
    --cui-btn-hover-color: #fff;
    --cui-btn-active-bg: #39f;
    --cui-btn-active-border-color: #39f;
    --cui-btn-active-color: #fff;
    --cui-btn-visited-bg: #39f;
    --cui-btn-visited-border-color: #39f;
    --cui-btn-visited-color: #fff;
    --cui-btn-disabled-color: #39f;
}
.btn-info {
    --cui-btn-active-color: #fff;
}

.page-item-w.active .page-link {
    background-color: #141414;
    border-color: #141414;
    padding: 5px 15px;
    margin: 0.4rem 0.8rem !important;
    border-radius: 50%;
    color: #fff;
}

.page-item-m.active .page-link {
    background-color: #141414;
    border-color: #141414;
    padding: 4px 11px;
    margin: 0.1rem 0.7rem !important;
    border-radius: 50%;
    color: #fff;
}

.top-btn-w {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 300;
}

.top-btn {
    border-radius: 50%;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    --cui-btn-hover-border-color: #d1d1d1;
    --cui-btn-shadow: none !important;
}

.top-btn:hover {
    background-color: #141414;
    border: 1px solid #141414;
}

.top-btn-icon {
    // src: url("../../src/assets/images/top-btn.png");
    background-image: url("../../src/assets/images/top-btn.png");
    background-size: cover;
    width: 50px;
    height: 50px;
    padding: 10px;
    border: 0px;
    border-style: hidden;
}
.top-btn-icon:hover {
    background-image: url("../../src/assets/images/top-btn-wh.png");
    background-size: cover;
    padding: 10px;
}